import React from 'react';
import { graphql, Link } from 'gatsby';
// import postQuery from "../queries/posts";
import Layout from '../components/layout'

export default class E404Page extends React.Component {
  render() {
    const pageEdges = this.props.data.allSitePage.edges;
    return (
    
      <Layout location={this.props.location}>
        <article className='page'>
          <h1>404 Error</h1>
          <p>File not found</p>
          <hr/>
          <h2>Files</h2>
          <p>Here is a subset of things that do exist:</p>
          <ul>{
            pageEdges.map(p => (
              <li><Link href={p.node.path}>{p.node.path}</Link></li>
            ))
          }</ul>
        </article>
      </Layout>
    );
  }
}

/* eslint no-undef: "off"*/
export const pageQuery =  graphql`
  query E404Page {
    site {
      siteMetadata {
        title
        author
        description
        libravatar
      }
    }
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;
